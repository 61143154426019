.list-container {
    height: 600px;
    overflow-y: auto;
}

.list-container::-webkit-scrollbar-thumb {
    background-color: #bbbbbb;
    border-radius: 5px;
}

.list-container::-webkit-scrollbar-button {
    display: none;
}

.list-container::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
}