@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-pagination-item:not(.ant-pagination-item-active),
.ant-pagination-item-link {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.ant-pagination-item,
.ant-pagination-item-link {
  border-radius: 3px !important;
}

/* rainbowkit modal layout style override */
.iekbcc0.ju367v8a.ju367v6j.ju367v74.ju367v7p {
  max-height: 600px !important;
  overflow-y: auto;
}

.swal2-container {
  z-index: 2147483647;
}

/* .swal2-popup {
  width: 350px;
} */

.swal2-html-container {
  margin: 0;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
