.flip-card {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    cursor: pointer;
}

.flip-card-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: 100%;
    
    padding: 0;
    position: relative;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    
    background-color: #fcfbfb;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

/* 
.flip-card:active .flip-card-inner {
    transform: rotateY(180deg);
} */

.flipped-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 20px; */

    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    color: black;
}

.flip-card-back {
    padding-bottom: 20px;
    position: absolute;
    background-color: #fcfbfb;
    color: white;
    border-radius: 3px;
    transform: rotateY(180deg);
    overflow: hidden;
}

.metadata-area {
    border: 1px solid #bbbbbb;
    color: gray;
    font-size: x-small;
    height: 150px;
    overflow-y: auto;
    border-radius: 5px;
}

.metadata-area::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
}

.metadata-area::-webkit-scrollbar-button {
    display: none;
}

.metadata-area::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

a {
    color: dodgerblue !important;
}