.nft-content {
    height: 300px;
    width: fit-content;
    justify-self: center;
    align-self: center;
    /* border-radius: 5px; */
}

.react-multi-carousel-list {
    height: 450px;
    position: relative;
}

.react-multi-carousel-item  {
    display: flex;
    justify-self: center;
    align-items: center;
    position: relative;

    transition: all 0.5s;
}

.react-multi-carousel-item--active {
    margin-left: 10px;
    margin-right: 10px;

    transform: scale(1.2);

    transition: all 0.5s;
    z-index: 1;
}
/* .react-multi-carousel-item  {
    display: flex !important;
    align-items: center !important;
    align-self: center;
}

.react-multi-carousel-item div {
    display: flex !important;
} */